import React from "react";
import HpCarousel from "./carousel/Carousel";
import Dergiler from "./dergiler/Dergiler";
import Icons from "./Icons/Icons";
import Popup from "./popup/Modal";
import "./Homepage.css";
function Homepage() {
  // (function () {
  //   if (window.localStorage) {
  //     if (!localStorage.getItem("firstLoad")) {
  //       localStorage["firstLoad"] = true;
  //       setTimeout(function () {
  //         window.location.reload();
  //       }, 0.1);
  //     } else localStorage.removeItem("firstLoad");
  //     setTimeout(function () {
  //       window.location.reload();
  //     }, 600000);
  //   }
  // })();

  return (
    <>
      {window.innerWidth < 768 ? null : <Popup></Popup>}
      <HpCarousel></HpCarousel>
      <Icons></Icons>
      <Dergiler />
    </>
  );
}

export default Homepage;
