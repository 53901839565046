let TrioAkademik = [
    {
        isim: "Trio Journal",
        ozet: "Akademik Dergi",
        kapak: "trio-eylül-2015.jpg",
        klasor: 'kapaklar/TrioAkademik/',
        link: "https://dergi.optisyeninsesi.com/trio/eylul2015",
        indir: 'https://dergi.optisyeninsesi.com/trio/eylul2015/EYLUL2015A.pdf'
    },
    {
        isim: "Trio Journal",
        ozet: "Akademik Dergi",
        kapak: "trio-agustos-2015-kapak.jpg",
        klasor: 'kapaklar/TrioAkademik/',
        link: "https://dergi.optisyeninsesi.com/trio/agustos2015",
        indir: 'https://dergi.optisyeninsesi.com/trio/agustos2015/AGUSTOS2015A.pdf'
    },
    {
        isim: "Trio Journal",
        ozet: "Akademik Dergi",
        kapak: "trio-temmuz-2015-kapak.jpg",
        klasor: 'kapaklar/TrioAkademik/',
        link: "https://dergi.optisyeninsesi.com/trio/temmuz2015",
        indir: 'https://dergi.optisyeninsesi.com/trio/temmuz2015/TEMMUZ2015A.pdf'
    },
];

export default TrioAkademik;
