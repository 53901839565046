import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <ul>
        <li className="footerBorder">
          <a href="http://www.optisyeninsesi.com">
            <img
              className="footerLogo"
              width="130px"
              src={require("./icons/logo (1).png")}
              alt=""
            />
          </a>
        </li>
        <li className="footerInfo footerBorder">
          <span className="izinsiz">
            © 2005 OptisyeninSesi | İzinsiz ve kaynak gösterilmeden
            yayınlanamaz.
            <br /> Whatsapp Mesaj Hattı: 0507 316 9645
            <br />
          </span>
        </li>
        <div className="footerSocials">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/optisyeninsesihaber/"
            >
              <img src={require("./icons/fb.png")} alt="" />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/optisyeninsesi"
            >
              <img src={require("./icons/twt.png")} alt="" />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://plus.google.com/110267815015699512147"
            >
              <img src={require("./icons/g+.png")} alt="" />
            </a>
          </li>

          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/user/optisyeninsesi"
            >
              <img src={require("./icons/ytb.png")} alt="" />
            </a>
          </li>
        </div>
        <p className="footerKK">
          Made by{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://kkureli.github.io/Portfolio/"
          >
            KK
          </a>
        </p>
      </ul>
    </div>
  );
};

export default Footer;
