import React from "react";
import "./Ikonlar.css";
const Icons = () => {
  return (
    <div className="ikonlar">
      <div>
        <img alt="dijital" src="https://img.icons8.com/nolan/64/news.png" />
        <span>Türkiye’nin dijital optik dergisi</span>
      </div>

      <div>
        <img
          alt="cihazlar"
          src="https://img.icons8.com/nolan/64/multiple-devices.png"
        />
        <span>Tüm cihazlarda erişilebilir</span>
      </div>

      <div>
        <img alt="7/24" src="https://img.icons8.com/nolan/64/time.png" />
        <span>7/24 Erişim</span>
      </div>

      <div>
        <img alt="indir" src="https://img.icons8.com/nolan/64/download.png" />
        <span>İndir & Oku</span>
      </div>
    </div>
  );
};

export default Icons;
