import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
// import "firebase/analytics";

export const config = {
  apiKey: "AIzaSyDvMrFA1jr_tQEOqJIMQdzmBl3MTIaJzX8",
  authDomain: "dergi-optisyeninsesi.firebaseapp.com",
  databaseURL: "https://dergi-optisyeninsesi.firebaseio.com",
  projectId: "dergi-optisyeninsesi",
  storageBucket: "dergi-optisyeninsesi.appspot.com",
  messagingSenderId: "103626871404",
  appId: "1:103626871404:web:2235232467a34b58d33e49",
  measurementId: "G-3LBT87E65R",
};
// Initialize Firebase
firebase.initializeApp(config);
// firebase.analytics();

firebase.firestore();

export default firebase;
