import React from "react";
import ScrollableTabsButtonAuto from "../../pages/butunSayilar/tabs/Tabs";
import "./ButunSayilar.css";
import Carousel from "./carousel/Carousel";
const ButunSayilar = () => {
  return (
    <div className="tabs">
      <Carousel></Carousel>
      <h2 className="butunSayilarTitle">Bütün Sayılar</h2>
      <ScrollableTabsButtonAuto></ScrollableTabsButtonAuto>
    </div>
  );
};

export default ButunSayilar;
