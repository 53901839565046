let dergiler2014 = [
    {
        isim: "Aralık 2014",
        ozet: "",
        kapak: "aralik-2014-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/aralik2014",
        indir: 'https://dergi.optisyeninsesi.com/aralik2014/optsesi-aralik-2014-dergi.pdf'
    },
    {
        isim: "Kasım 2014",
        ozet: "",
        kapak: "kasim-2014-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/kasim2014",
        indir: 'https://dergi.optisyeninsesi.com/kasim2014/os-kasim-2014.pdf'
    },
    {
        isim: "Ekim 2014",
        ozet: "",
        kapak: "ekim2014_kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/ekim2014",
        indir: 'https://dergi.optisyeninsesi.com/os-ekim-2014.pdf'
    },
    {
        isim: "Eylül 2014",
        ozet: "",
        kapak: "eylul-2014.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/eylul2014",
        indir: 'https://dergi.optisyeninsesi.com/OPT%20EYLUL%202014_1_.pdf'
    },
    {
        isim: "Agustos 2014",
        ozet: "",
        kapak: "agustos-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/agustos2014",
        indir: 'https://dergi.optisyeninsesi.com/OPT%20AGUSTOS%202014.PDF'
    },
    {
        isim: "Temmuz 2014",
        ozet: "",
        kapak: "temmuz-2014-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/temmuz2014",
        indir: 'https://dergi.optisyeninsesi.com/OPT%20TEMMUZ%202014.pdf'
    },
    {
        isim: "Haziran 2014",
        ozet: "",
        kapak: "haziran-2014-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/haziran2014",
        indir: ''
    },
    {
        isim: "Mayıs 2014",
        ozet: "",
        kapak: "mayis_kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/mayis2014",
        indir: ''
    },
    {
        isim: "Nisan 2014",
        ozet: "",
        kapak: "nisan_kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/nisan2014",
        indir: ''
    },
    {
        isim: "Mart 2014",
        ozet: "",
        kapak: "mart-dergi-kapak.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/mart2014",
        indir: ''
    },
    {
        isim: "Şubat 2014",
        ozet: "",
        kapak: "subat_2014.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/subat2014",
        indir: ''
    },
    {
        isim: "Ocak 2014",
        ozet: "",
        kapak: "ocak2014.jpg",
        klasor: 'kapaklar/2014kapak/',
        link: "http://dergi.optisyeninsesi.com/ocak2014",
        indir: ''
    },
];

export default dergiler2014;
