import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

import "./MapperStyle.css";
const Mapper = (props) => {
  const { dergiler } = props;
  return (
    dergiler &&
    dergiler.map((dergi, index) => {
      const baseUrl = process.env.PUBLIC_URL + dergi.klasor + dergi.kapak;
      return (
        <Col className="dergiCol" key={index} md={3}>
          <Card className="mt-4" style={{ width: "13rem" }}>
            <a rel="noopener noreferrer" target="_blank" href={dergi.link}>
              <Card.Img variant="top" src={baseUrl} />
            </a>
            <Card.Body style={{ height: "200px", overflowY: "auto" }}>
              <Card.Title>{dergi.isim}</Card.Title>
              <Card.Text>{dergi.ozet}</Card.Text>
              <Button variant="primary">
                <a
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                  target="_blank"
                  href={dergi.link}
                >
                  Oku
                </a>
              </Button>
              <Button className="ml-3" variant="danger">
                <a
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                  target="_blank"
                  href={dergi.indir}
                >
                  İndir
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      );
    })
  );
};

export default Mapper;
