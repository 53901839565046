import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "react-bootstrap/Button";
import "./Popup.css";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const TransitionsModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  //   const [popupSeen, setSeen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    // setSeen(true);
  };

  //   setTimeout(function () {
  //     setOpen(false)
  //   }, 1000);

  const { popup } = props;

  if (popup && popup[0].acik) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={[classes.modal, "popUp"].join(" ")}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              {/* <h2 id="transition-modal-title">Transition modal</h2> */}
              <p id="transition-modal-description">
                <a target="__blank" href={popup[0].link}>
                  <img width="100%" src={popup[0].foto} alt="" />
                </a>
              </p>
              <div>
                <Button
                  // variant="danger"
                  onClick={handleClose}
                >
                  Kapat
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    popup: state.firestore.ordered.popup,
  };
};
export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      {
        collection: "popup",
      },
    ];
  })
)(TransitionsModal);
