import React from "react";

import "./Dergiler.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import YeniMapper from "./Mapper";

const Dergiler = (props) => {
  const { dergiler } = props;
  console.log("DERGILER:", dergiler);
  return (
    <div className="dergiler">
      <Container>
        <h3>Son Sayılar</h3>
        <Row>
          {dergiler ? (
            <YeniMapper
              dergiler={dergiler.filter((e) => e.eski !== true)}
            ></YeniMapper>
          ) : (
            "Yükleniyor.."
          )}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dergiler: state.firestore.ordered.anasayfaDergiler,
  };
};
export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      {
        collection: "anasayfaDergiler",
        orderBy: ["ay", "desc"],
      },
    ];
  })
)(Dergiler);
