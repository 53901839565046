let dergiler2013 = [
    {
        isim: "Aralık 2013",
        ozet: "",
        kapak: "aralik2013.png",
        klasor: 'kapaklar/2013kapak/',
        link: "http://dergi.optisyeninsesi.com/aralikdergi/",
        indir: ''
    },
    {
        isim: "Kasım 2013",
        ozet: "",
        kapak: "kasim2013.png",
        klasor: 'kapaklar/2013kapak/',
        link: "http://dergi.optisyeninsesi.com/kasimdergi/",
        indir: ''
    },
    {
        isim: "Ekim 2013",
        ozet: "",
        kapak: "ekim2013.png",
        klasor: 'kapaklar/2013kapak/',
        link: "http://dergi.optisyeninsesi.com/ekimdergi/",
        indir: ''
    },
    {
        isim: "Eylül 2013",
        ozet: "",
        kapak: "eylul2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "http://dergi.optisyeninsesi.com/eyluldergi/",
        indir: ''
    },
    {
        isim: "Agustos 2013",
        ozet: "",
        kapak: "agustos2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Temmuz 2013",
        ozet: "",
        kapak: "temmuz2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Haziran 2013",
        ozet: "",
        kapak: "haziran2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Mayıs 2013",
        ozet: "",
        kapak: "mayis2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Nisan 2013",
        ozet: "",
        kapak: "nisan2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Mart 2013",
        ozet: "",
        kapak: "mart2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Şubat 2013",
        ozet: "",
        kapak: "subat2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Ocak 2013",
        ozet: "",
        kapak: "ocak2013.jpg",
        klasor: 'kapaklar/2013kapak/',
        link: "",
        indir: ''
    },
];

export default dergiler2013;
