import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Loop, Transform } from "react-animation-components";
import "./MapperStyle.css";
const YeniMapper = (props) => {
  const { dergiler } = props;
  return (
    dergiler &&
    dergiler?.map((dergi, index) => {
      if (index === 0) {
        return (
          <Col className="dergiCol" key={index} md={3}>
            <Loop in>
              <Transform
                enterTransform="scale(1.1)"
                style={{ display: "inline-block" }}
                timeout={100}
              >
                <Card className="mt-4" style={{ width: "13rem" }}>
                  <a rel="noopener noreferrer" target="_blank" href={dergi.oku}>
                    <Card.Img variant="top" src={dergi.foto} />
                  </a>
                  <Card.Body style={{ height: "200px", overflowY: "auto" }}>
                    <Card.Title>{dergi.isim}</Card.Title>
                    <Card.Text>{dergi.bilgi}</Card.Text>
                    <Button variant="primary">
                      <a
                        rel="noopener noreferrer"
                        style={{ color: "white" }}
                        target="_blank"
                        href={dergi.oku}
                      >
                        Oku
                      </a>
                    </Button>
                    <Button className="ml-3" variant="danger">
                      <a
                        rel="noopener noreferrer"
                        style={{ color: "white" }}
                        target="_blank"
                        href={dergi.indir}
                      >
                        İndir
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Transform>
            </Loop>
          </Col>
        );
      } else {
        return (
          <Col className="dergiCol" key={index} md={3}>
            <Card className="mt-4" style={{ width: "13rem" }}>
              <a rel="noopener noreferrer" target="_blank" href={dergi.oku}>
                <Card.Img variant="top" src={dergi.foto} />
              </a>
              <Card.Body style={{ height: "200px", overflowY: "auto" }}>
                <Card.Title>{dergi.isim}</Card.Title>
                <Card.Text>{dergi.bilgi}</Card.Text>
                <Button variant="primary">
                  <a
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                    target="_blank"
                    href={dergi.oku}
                  >
                    Oku
                  </a>
                </Button>
                <Button className="ml-3" variant="danger">
                  <a
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                    target="_blank"
                    href={dergi.indir}
                  >
                    İndir
                  </a>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        );
      }
    })
  );
};

export default YeniMapper;
