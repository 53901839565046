let dergiler2015 = [
    {
        isim: "Aralık 2015",
        ozet: "",
        kapak: "aralik-2015-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/aralik2015",
        indir: 'http://dergi.optisyeninsesi.com/aralik2015/OPT-ARALIK-2015.pdf'
    },
    {
        isim: "Kasım 2015",
        ozet: "",
        kapak: "kasim-dergi-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/kasim2015",
        indir: 'http://dergi.optisyeninsesi.com/kasim2015/OPT-KASIM-2015.pdf'
    },
    {
        isim: "Ekim 2015",
        ozet: "",
        kapak: "ekim-dergi-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/ekim2015",
        indir: 'http://dergi.optisyeninsesi.com/eylul2015/OPT-EKIM-2015.pdf'
    },
    {
        isim: "Eylül 2015",
        ozet: "",
        kapak: "eylul-2015-dergi-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/eylul2015",
        indir: 'http://dergi.optisyeninsesi.com/eylul2015/OPT-EYLUL-2015.pdf'
    },
    {
        isim: "Agustos 2015",
        ozet: "",
        kapak: "agustos-2015-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/agustos2015",
        indir: 'http://dergi.optisyeninsesi.com/agustos2015/OPT-AGUSTOS-2015.pdf'
    },
    {
        isim: "Temmuz 2015",
        ozet: "",
        kapak: "temmuz-2015-dergi-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/temmuz2015",
        indir: 'http://dergi.optisyeninsesi.com/temmuz2015/TEMMUZ2015.pdf'
    },
    {
        isim: "Haziran 2015",
        ozet: "",
        kapak: "haziran-2015-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/haziran2015",
        indir: 'http://dergi.optisyeninsesi.com/haziran2015/HAZIRAN2015.pdf'
    },
    {
        isim: "Mayıs 2015",
        ozet: "",
        kapak: "mayis-2015-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/mayis2015",
        indir: 'http://dergi.optisyeninsesi.com/mayis2015/MAYIS2015.pdf'
    },
    {
        isim: "Nisan 2015",
        ozet: "",
        kapak: "nisan-2015-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/nisan2015",
        indir: 'http://dergi.optisyeninsesi.com/nisan2015/NISAN2015.pdf'
    },
    {
        isim: "Mart 2015",
        ozet: "",
        kapak: "mart-2015--kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/mart2015",
        indir: 'http://dergi.optisyeninsesi.com/mart2015/MART2015.pdf'
    },
    {
        isim: "Şubat 2015",
        ozet: "",
        kapak: "subat-2015--kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/subat2015",
        indir: 'http://dergi.optisyeninsesi.com/subat2015/SUBAT2015.pdf'
    },
    {
        isim: "Ocak 2015",
        ozet: "",
        kapak: "ocak-dergi-kapak.jpg",
        klasor: 'kapaklar/2015kapak/',
        link: "http://dergi.optisyeninsesi.com/ocak2015",
        indir: 'http://dergi.optisyeninsesi.com/ocak2015/OPT%20OCAK%202015.pdf'
    },
];

export default dergiler2015;
