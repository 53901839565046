let dergiler2016 = [
    {
        isim: "Aralık 2016",
        ozet: "",
        kapak: "aealik-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/aralik2016",
        indir: 'http://dergi.optisyeninsesi.com/aralik2016/OPT-ARALIK-2016.pdf'
    },
    {
        isim: "Kasım 2016",
        ozet: "",
        kapak: "kasim-2016-dergi-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/kasim2016",
        indir: 'http://dergi.optisyeninsesi.com/kasim2016/OPT-KASIM-2016.pdf'
    },
    {
        isim: "Ekim 2016",
        ozet: "",
        kapak: "ekim-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/ekim2016",
        indir: 'http://dergi.optisyeninsesi.com/ekim2016/OPT-EKIM-2016.pdf'
    },
    {
        isim: "Eylül 2016",
        ozet: "",
        kapak: "eylul-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/eylul2016",
        indir: 'http://dergi.optisyeninsesi.com/eylul2016/OPT-EYLUL-2016.pdf'
    },
    {
        isim: "Agustos 2016",
        ozet: "",
        kapak: "agustos-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/agustos2016",
        indir: 'http://dergi.optisyeninsesi.com/agustos2016/OPT-AGUSTOS-2016.pdf'
    },
    {
        isim: "Temmuz 2016",
        ozet: "",
        kapak: "opt-temmuz-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/temmuz2016",
        indir: 'http://dergi.optisyeninsesi.com/temmuz2016/OPT-TEMMUZ-2016.pdf'
    },
    {
        isim: "Haziran 2016",
        ozet: "",
        kapak: "haziran-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/haziran2016",
        indir: 'http://dergi.optisyeninsesi.com/haziran2016/OPT-HAZIRAN-2016.pdf'
    },
    {
        isim: "Mayıs 2016",
        ozet: "",
        kapak: "mayis-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/mayis2016",
        indir: 'http://dergi.optisyeninsesi.com/mayis2016/OPT-MAYIS-2016.pdf'
    },
    {
        isim: "Nisan 2016",
        ozet: "",
        kapak: "nisan-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/nisan2016",
        indir: 'http://dergi.optisyeninsesi.com/nisan2016/OPT-NISAN-2016.pdf'
    },
    {
        isim: "Mart 2016",
        ozet: "",
        kapak: "mart-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/mart2016",
        indir: 'http://dergi.optisyeninsesi.com/mart2016/OPT-MART-2016.pdf'
    },
    {
        isim: "Şubat 2016",
        ozet: "",
        kapak: "subat-2016--kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/subat2016",
        indir: 'http://dergi.optisyeninsesi.com/subat2016/OPT-SUBAT-2016.pdf'
    },
    {
        isim: "Ocak 2016",
        ozet: "",
        kapak: "ocak-2016-kapak.jpg",
        klasor: 'kapaklar/2016kapak/',
        link: "http://dergi.optisyeninsesi.com/ocak2016",
        indir: 'http://dergi.optisyeninsesi.com/ocak2016/OPT-OCAK-2016.pdf'
    },
];

export default dergiler2016;
