let dergiler2017 = [
    {
        isim: "Aralık 2017",
        ozet: "",
        kapak: "aralik-2017.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/aralik2017",
        indir: 'http://dergi.optisyeninsesi.com/aralik2017/OPT-ARALIK-2017.pdf'
    },
    {
        isim: "Kasım 2017",
        ozet: "",
        kapak: "kasim-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/kasim2017",
        indir: 'http://dergi.optisyeninsesi.com/kasim2017/OPT-KASIM-2017.pdf'
    },
    {
        isim: "Ekim 2017",
        ozet: "",
        kapak: "ekim-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/ekim2017",
        indir: 'http://dergi.optisyeninsesi.com/ekim2017/OPT-EKIM-2017.pdf'
    },
    {
        isim: "Eylül 2017",
        ozet: "",
        kapak: "eylul-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/eylul2017",
        indir: 'http://dergi.optisyeninsesi.com/eylul2017/OPT-EYLUL-2017.pdf'
    },
    {
        isim: "Agustos 2017",
        ozet: "",
        kapak: "agustos-2017.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/agustos2017",
        indir: 'http://dergi.optisyeninsesi.com/agustos2017/OPT-AGUSTOS-2017.pdf'
    },
    {
        isim: "Temmuz 2017",
        ozet: "",
        kapak: "temmuz-2017.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/temmuz2017",
        indir: 'http://dergi.optisyeninsesi.com/temmuz2017/OPT_TEMMUZ_2017.pdf'
    },
    {
        isim: "Haziran 2017",
        ozet: "",
        kapak: "haziran-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/haziran2017",
        indir: 'http://dergi.optisyeninsesi.com/haziran2017/OPT-HAZIRAN-2017.pdf'
    },
    {
        isim: "Mayıs 2017",
        ozet: "",
        kapak: "mayis-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/mayis2017",
        indir: 'http://dergi.optisyeninsesi.com/mayis2017/OPT-MAYIS-2017.pdf'
    },
    {
        isim: "Nisan 2017",
        ozet: "",
        kapak: "nisan-2017-dergi-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/nisan2017",
        indir: 'http://dergi.optisyeninsesi.com/nisan2017/OPT-NISAN-2017.pdf'
    },
    {
        isim: "Mart 2017",
        ozet: "",
        kapak: "mart-2017-dergi-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/mart2017",
        indir: 'http://dergi.optisyeninsesi.com/mart2017/OPT-MART-2017.pdf'
    },
    {
        isim: "Şubat 2017",
        ozet: "",
        kapak: "subat-2017--dergi-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/subat2017",
        indir: 'http://dergi.optisyeninsesi.com/subat2017/OPT-SUBAT-2017.pdf'
    },
    {
        isim: "Ocak 2017",
        ozet: "",
        kapak: "ocak-2017-kapak.jpg",
        klasor: 'kapaklar/2017kapak/',
        link: "http://dergi.optisyeninsesi.com/ocak2017",
        indir: 'http://dergi.optisyeninsesi.com/ocak2017/OPT-OCAK-2017.pdf'
    },

];

export default dergiler2017;
