let dergiler2019 = [
  {
    isim: "Aralık 2019",
    ozet: "",
    kapak: "aralik2019kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/aralik2019",
    indir: 'http://dergi.optisyeninsesi.com/aralik2019/OPT-ARALIK-2019.pdf'
  },
  {
    isim: "Kasım 2019",
    ozet: "",
    kapak: "kasim2019kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/kasim2019",
    indir: 'http://dergi.optisyeninsesi.com/kasim2019/OPT-KASIM-2019.pdf'
  },
  {
    isim: "Ekim 2019",
    ozet: "",
    kapak: "ekim_2019-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/ekim2019",
    indir: 'http://dergi.optisyeninsesi.com/ekim2019/OPT-EK%C4%B0M-2019.pdf'
  },
  {
    isim: "Eylül 2019",
    ozet: "",
    kapak: "eylul-2019-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/eylul2019",
    indir: 'http://dergi.optisyeninsesi.com/eylul2019/OPT-EYLUL-2019.pdf'
  },
  {
    isim: "Agustos 2019",
    ozet: "",
    kapak: "agustos-2019-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/agustos2019",
    indir: 'http://dergi.optisyeninsesi.com/agustos2019/OPT-AGUSTOS-2019.pdf'
  },
  {
    isim: "Temmuz 2019",
    ozet: "",
    kapak: "temmuz-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/temmuz2019",
    indir: 'http://dergi.optisyeninsesi.com/temmuz2019/OPT-TEMMUZ-2019.pdf'
  },
  {
    isim: "Haziran 2019",
    ozet: "",
    kapak: "haziran-2019-dergi-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/haziran2019",
    indir: 'http://dergi.optisyeninsesi.com/haziran2019/opt-haziran-2019.pdf'
  },
  {
    isim: "Mayıs 2019",
    ozet: "",
    kapak: "mayis-2019-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/mayis2019",
    indir: 'http://dergi.optisyeninsesi.com/mayis2019/OPT-MAYIS-2019.pdf'
  },
  {
    isim: "Nisan 2019",
    ozet: "",
    kapak: "nisan-2019--kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/nisan2019",
    indir: 'http://dergi.optisyeninsesi.com/nisan2019/OPT-NISAN-2019.pdf'
  },
  {
    isim: "Mart 2019",
    ozet: "",
    kapak: "mart2019-dergi-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/mart2019",
    indir: 'http://dergi.optisyeninsesi.com/mart2019/OPT-MART-2019.pdf'
  },
  {
    isim: "Şubat 2019",
    ozet: "",
    kapak: "subat-2019-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/subat2019",
    indir: 'http://dergi.optisyeninsesi.com/subat2019/OPT-SUBAT-2019.pdf'
  },
  {
    isim: "Ocak 2019",
    ozet: "",
    kapak: "ocak-2019i-kapak.jpg",
    klasor: 'kapaklar/2019kapak/',
    link: "http://dergi.optisyeninsesi.com/ocak2019",
    indir: 'http://dergi.optisyeninsesi.com/ocak2019/OPT-OCAK-2019.pdf'
  },

];

export default dergiler2019;
