/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import dergiler2020 from "../../../arsiv/2020";
import dergiler2019 from "../../../arsiv/2019";
import dergiler2018 from "../../../arsiv/2018";
import dergiler2017 from "../../../arsiv/2017";
import dergiler2016 from "../../../arsiv/2016";
import dergiler2015 from "../../../arsiv/2015";
import dergiler2014 from "../../../arsiv/2014";
import dergiler2013 from "../../../arsiv/2013";
import dergiler2012 from "../../../arsiv/2012";
import TrioAkademik from "../../../arsiv/TrioAkademik";
import EskiMapper from "../../homepage/dergiler/EskiSayilarMapper";
import Row from "react-bootstrap/Row";

import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import YeniMapper from "../../homepage/dergiler/Mapper";

const archiveDergiler = {
  2020: dergiler2020,
  2019: dergiler2019,
  2018: dergiler2018,
  2017: dergiler2017,
  2016: dergiler2016,
  2015: dergiler2015,
  2014: dergiler2014,
  2013: dergiler2013,
  2012: dergiler2012,
  TrioAkademik,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const ScrollableTabsButtonAuto = (props) => {
  const { dergiler } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const archivedYears = React.useMemo(() => {
    if (dergiler?.anasayfaDergiler?.length > 0) {
      const list = {
        //[2022]:[{ay:"", bilgi:""}, {...} ]
      };
      dergiler.anasayfaDergiler.map((e) => {
        if (e.eski) {
          const splitted = e.isim.split(" ");
          if (!list[splitted[1]]) {
            list[splitted[1]] = [e];
          } else {
            list[splitted[1]] = [...list[splitted[1]], e];
          }
        }
      });
      console.log("LIST:", list);
      return list;
    }
  }, [dergiler]);

  const tabs = [
    { label: "2021" },
    { label: "2020" },
    { old: true, label: "2019" },
    { old: true, label: "2018" },
    { old: true, label: "2017" },
    { old: true, label: "2016" },
    { old: true, label: "2015" },
    { old: true, label: "2014" },
    { old: true, label: "2013" },
    { old: true, label: "2012" },
    { old: true, label: "Trio Akademik" },
  ];

  const newTabs = React.useMemo(() => {
    const filteredTabs = [];
    archivedYears &&
      Object.keys(archivedYears)?.length > 0 &&
      Object.keys(archivedYears).map((year) => {
        filteredTabs.push({ label: year });
      });
    tabs.map((tab) => {
      if (tab.old || dergiler[tab.label]?.length > 0) {
        filteredTabs.push(tab);
      }
    });
    debugger;
    const sorted = filteredTabs.sort(
      (a, b) => Number(b.label) - Number(a.label)
    ); // b - a for reverse sort

    return sorted;
  }, [archivedYears, dergiler, tabs]);

  console.log("archivedYears:", archivedYears);
  console.log("newTabs:", newTabs);
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {newTabs.map(({ label, old }, index) => {
            return <Tab key={label} label={label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </AppBar>
      {newTabs.map(({ label, old }, index) => {
        return (
          <TabPanel key={label} value={value} index={index}>
            <Row>
              {old ? (
                <EskiMapper
                  dergiler={archiveDergiler[label.replace(/\s+/g, "")]}
                />
              ) : archivedYears && archivedYears[label]?.length > 0 ? (
                <YeniMapper dergiler={archivedYears[label]} />
              ) : dergiler ? (
                <YeniMapper dergiler={dergiler[label]} />
              ) : (
                "Yükleniyor.."
              )}
            </Row>
          </TabPanel>
        );
      })}

      {/* <TabPanel value={value} index={0}>
        <Row>
          {dergiler[2021] ? (
            <YeniMapper dergiler={dergiler[2021]}></YeniMapper>
          ) : (
            "Yükleniyor.."
          )}
        </Row>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Row>
          {dergiler[2020] ? (
            <YeniMapper dergiler={dergiler[2020]}></YeniMapper>
          ) : (
            "Yükleniyor.."
          )}
        </Row>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Row>
          <EskiMapper dergiler={dergiler2019}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Row>
          <EskiMapper dergiler={dergiler2018}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Row>
          <EskiMapper dergiler={dergiler2017}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Row>
          <EskiMapper dergiler={dergiler2016}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Row>
          <EskiMapper dergiler={dergiler2015}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Row>
          <EskiMapper dergiler={dergiler2014}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Row>
          <EskiMapper dergiler={dergiler2013}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Row>
          <EskiMapper dergiler={dergiler2012}></EskiMapper>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <Row>
          <EskiMapper dergiler={TrioAkademik}></EskiMapper>
        </Row>
      </TabPanel> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dergiler: state.firestore.ordered,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    console.log("PROPS:", props);
    return [
      {
        collection: "2020",
        orderBy: ["ay", "desc"],
      },
      {
        collection: "2021",
        orderBy: ["ay", "desc"],
      },
      {
        collection: "anasayfaDergiler",
        orderBy: ["ay", "desc"],
      },
    ];
  })
)(ScrollableTabsButtonAuto);
