let dergiler2012 = [
    {
        isim: "Aralık 2012",
        ozet: "",
        kapak: "aralik2012.jpg",
        klasor: 'kapaklar/2012kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Kasım 2012",
        ozet: "",
        kapak: "kasim2012.jpg",
        klasor: 'kapaklar/2012kapak/',
        link: "",
        indir: ''
    },
    {
        isim: "Ekim 2012",
        ozet: "",
        kapak: "ekim2012.jpg",
        klasor: 'kapaklar/2012kapak/',
        link: "",
        indir: ''
    },
];

export default dergiler2012;
