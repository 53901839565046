let dergiler2020 = [
  {
    isim: "Mayıs 2020",
    ozet: "İyi Bayramlar",
    kapak: "mayıs2020.jpg",
    klasor: "kapaklar/2020kapak/",
    link: "https://dergi.optisyeninsesi.com/mayis2020",
    indir: "https://dergi.optisyeninsesi.com/mayis2020/opt-mayis-2020.pdf",
  },
  {
    isim: "Nisan 2020",
    ozet: "Evde Kal Sağlıklı Kal",
    kapak: "nisan2020.jpg",
    klasor: "kapaklar/2020kapak/",
    link: "https://dergi.optisyeninsesi.com/nisan2020",
    indir: "https://dergi.optisyeninsesi.com/nisan2020/OPT-NISAN-2020.pdf",
  },
  {
    isim: "Mart 2020",
    ozet: "Coronavirüs ve Gözlük Sektörü",
    kapak: "mart.jpg",
    klasor: "kapaklar/2020kapak/",
    link: "http://dergi.optisyeninsesi.com/mart2020",
    indir: "http://dergi.optisyeninsesi.com/mart2020/OPT-MART-2020.pdf",
  },
  {
    isim: "Şubat 2020",
    ozet: "Sektorde Krizle Muacadele Yontemleri",
    kapak: "subat.jpg",
    klasor: "kapaklar/2020kapak/",
    link: "http://dergi.optisyeninsesi.com/subat2020",
    indir: "http://dergi.optisyeninsesi.com/subat2020/opt-subat-2020.pdf",
  },
  {
    isim: "Ocak 2020",
    ozet: "Yeni yıl yeni umutlar",
    kapak: "ocak.jpg",
    klasor: "kapaklar/2020kapak/",
    link: "http://dergi.optisyeninsesi.com/ocak2020",
    indir: "http://dergi.optisyeninsesi.com/ocak2020/OPT-OCAK-2020-.pdf",
  },
  {
    isim: "Trio Journal",
    ozet: "Akademik Dergi",
    kapak: "trio-eylül-2015.jpg",
    klasor: "kapaklar/TrioAkademik/",
    link: "https://dergi.optisyeninsesi.com/trio/eylul2015",
    indir: "https://dergi.optisyeninsesi.com/trio/eylul2015/EYLUL2015A.pdf",
  },
  {
    isim: "Trio Journal",
    ozet: "Akademik Dergi",
    kapak: "trio-agustos-2015-kapak.jpg",
    klasor: "kapaklar/TrioAkademik/",
    link: "https://dergi.optisyeninsesi.com/trio/agustos2015",
    indir: "https://dergi.optisyeninsesi.com/trio/agustos2015/AGUSTOS2015A.pdf",
  },
  {
    isim: "Trio Journal",
    ozet: "Akademik Dergi",
    kapak: "trio-temmuz-2015-kapak.jpg",
    klasor: "kapaklar/TrioAkademik/",
    link: "https://dergi.optisyeninsesi.com/trio/temmuz2015",
    indir: "https://dergi.optisyeninsesi.com/trio/temmuz2015/TEMMUZ2015A.pdf",
  },
];

export default dergiler2020;
