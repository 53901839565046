let dergiler2018 = [
    {
        isim: "Aralık 2018",
        ozet: "",
        kapak: "aralik-dergi-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/aralik2018",
        indir: 'http://dergi.optisyeninsesi.com/aralik2018/OPT-ARALIK-2018.pdf'
    },
    {
        isim: "Kasım 2018",
        ozet: "",
        kapak: "kasim-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/kasim2018",
        indir: 'http://dergi.optisyeninsesi.com/kasim2018/opt-kasim-2018.pdf'
    },
    {
        isim: "Ekim 2018",
        ozet: "",
        kapak: "ekim-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/ekim2018",
        indir: 'http://dergi.optisyeninsesi.com/eylul2018/OPT-EYLUL-2018.pdf'
    },
    {
        isim: "Eylül 2018",
        ozet: "",
        kapak: "eylul-2018-kapak..jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/eylul2018",
        indir: 'http://dergi.optisyeninsesi.com/eylul2018/OPT-EYLUL-2018.pdf'
    },
    {
        isim: "Agustos 2018",
        ozet: "",
        kapak: "agustos-2018--dergi-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/agustos2018",
        indir: 'http://dergi.optisyeninsesi.com/agustos2018/OPT-TEMMUZ-2018.pdf'
    },
    {
        isim: "Temmuz 2018",
        ozet: "",
        kapak: "temmuz-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/temmuz2018",
        indir: 'http://dergi.optisyeninsesi.com/temmuz2018/OPT-TEMMUZ-2018.pdf'
    },
    {
        isim: "Haziran 2018",
        ozet: "",
        kapak: "haziran-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/haziran2018",
        indir: 'http://dergi.optisyeninsesi.com/haziran2018/OPT-HAZIRAN-2018.pdf'
    },
    {
        isim: "Mayıs 2018",
        ozet: "",
        kapak: "mayis-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/mayis2018",
        indir: 'http://dergi.optisyeninsesi.com/mayis2018/OPT-MAYIS-2018.pdf'
    },
    {
        isim: "Nisan 2018",
        ozet: "",
        kapak: "nisan-2018i-dergi-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/nisan2018",
        indir: 'http://dergi.optisyeninsesi.com/nisan2018/OPT-NISAN-2018.pdf'
    },
    {
        isim: "Mart 2018",
        ozet: "",
        kapak: "mart-2018-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/mart2018",
        indir: 'http://dergi.optisyeninsesi.com/mart2018/OPT-MART-2018.pdf'
    },
    {
        isim: "Şubat 2018",
        ozet: "",
        kapak: "subat-2018--kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/subat2018",
        indir: 'http://dergi.optisyeninsesi.com/subat2018/OPT-SUBAT-2018.pdf'
    },
    {
        isim: "Ocak 2018",
        ozet: "",
        kapak: "ocak2018-dergi-kapak.jpg",
        klasor: 'kapaklar/2018kapak/',
        link: "http://dergi.optisyeninsesi.com/ocak2018",
        indir: 'http://dergi.optisyeninsesi.com/ocak2018/OPT-OCAK-2018.pdf'
    },

];

export default dergiler2018;
